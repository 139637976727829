import { Add, HelpOutlineRounded, SendOutlined } from '@mui/icons-material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Avatar, TextField } from '@mui/material';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DashboardLayout from './../layouts/DashboardLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BottomDrawer from './../shared/BottomDrawer';
import Details from './../shared/Details';

const Messages = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  return (
    <DashboardLayout>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Details />
      </BottomDrawer>
      <Box height='100vh' mx={{ md: '14%' }}>
        <Box px={1} py={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={700} fontSize={20}>
                Message
              </Typography>
            </Box>
            <HelpOutlineRounded
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setOpen(true);
              }}
            />
          </Box>
          <Box p={2}>
            <Box display='flex' alignItems='center' gap={1} mb='6px'>
              <Avatar />
              <Typography fontSize={16} fontWeight={600}>
                John Doe
              </Typography>
              <Typography fontSize={12}>Online</Typography>
            </Box>
            <Typography
              fontSize={12}
              bgcolor='#F7FFEC'
              p='10px'
              borderRadius='4px'
              mb='24px'
            >
              You can only recieve message from a customer during a live trip.
            </Typography>
            {[1, 2].map((_) => (
              <>
                {/* {Senders message} */}
                <Box mb='12px'>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    width='80%'
                  >
                    <Typography fontSize={14} fontWeight={600}>
                      Phoenix Baker
                    </Typography>
                    <Typography fontSize={12}>Friday 2:20pm</Typography>
                  </Box>
                  <Typography
                    fontSize={14}
                    width='80%'
                    bgcolor='#F9FAFB'
                    border='1px solid #E4E7EC'
                    p='10px 14px 10px 14px'
                    borderRadius='0px 8px 8px 8px'
                  >
                    Hey Olivia, can you please review the latest design when you
                    can?
                  </Typography>
                </Box>
                {/* {revievers message} */}
                <Box
                  mb='12px'
                  display='flex'
                  alignItems='end'
                  justifyContent='flex-end'
                >
                  <Box maxWidth='80%'>
                    <Typography
                      fontSize={12}
                      display='flex'
                      alignItems='end'
                      justifyContent='flex-end'
                    >
                      Friday 2:20pm
                    </Typography>
                    <Typography
                      fontSize={14}
                      color='white'
                      bgcolor='#379E66'
                      p='10px 14px 10px 14px'
                      borderRadius='8px 0px 8px 8px'
                    >
                      Sure thing, I'll have a look today.
                    </Typography>
                  </Box>
                </Box>
              </>
            ))}
            <Box display='flex' position='fixed' width='90%' mb={2}>
              <TextField
                variant='standard'
                InputProps={{ disableUnderline: true }}
                placeholder='Reply'
                sx={{
                  p: '10px 0px  10px 14px',
                  borderRadius: '8px',
                  bgcolor: '#F9F9F9',
                  mr: '10px',
                  width: { xs: '100%', md: '70%' },
                }}
              />
              <Box
                height='44px'
                width='44px'
                border='2px solid black'
                borderRadius='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
                p='10px'
                sx={{ cursor: 'pointer' }}
                marginRight='10px'
              >
                <Add
                  sx={{
                    color: 'black',
                    height: '34px',
                    width: '34px',
                  }}
                />
              </Box>
              <Box
                height='44px'
                width='44px'
                bgcolor='#379E66'
                display='flex'
                justifyContent='center'
                alignItems='center'
                borderRadius='100%'
                p='10px'
                sx={{ cursor: 'pointer' }}
              >
                <SendOutlined
                  sx={{ color: 'white', height: '24px', width: '24px' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Messages;
