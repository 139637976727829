import {
  Box,
  Button,
  FormControl,
  InputLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import {
  ArrowBack,
  Check,
  Apple,
  AccountCircle,
  Close,
  Phone,
} from '@mui/icons-material';

import { ReactComponent as Google } from '../../assets/google.svg';
import move9jaLogo from '../../assets/move9ja.svg';
import backGroundImage from '../../assets/move9jaBoy.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { IUser, sendOtp as login, loginCustomers } from '../../services/auth';

interface IFormInputs {
  email: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required'),
  })
  .required();

const Login = () => {
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (data: Pick<IUser, 'email'>) => {
      return await loginCustomers({ ...data, type: 'email' });
    },
    onSuccess: (data) => {
      console.log('on Success', data);
      localStorage.setItem('token', data?.data?.token);

      navigate('/login-otp', { state: { userId: data?.data?.user_id } });
    },
    onError: (error) => {
      // Handle error
      console.error('Error logging in:', error);
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFormInputs) => {
    mutation.mutate(data);
  };

  return (
    <Box height='100vh' sx={{ position: 'fixed', width: '100%' }}>
      <Box
        height='72px'
        p='0px 80px'
        display={{ xs: 'none', md: 'flex' }}
        alignItems='center'
        justifyContent='space-between'
        border='1px solid #E4E7EC'
      >
        <img src={move9jaLogo} alt='' width='162.75px' height='50.26px' />
        {/* <Button
          sx={{
            bgcolor: '#DA4733',
            width: '109px',
            borderRadius: '8px',
            fontWeight: 600,
            fontSize: '14px',
          }}
          variant='contained'
        >
          Login
        </Button> */}
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'grid' },
          gridTemplateColumns: '40% 60%',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left center',
            height: 'calc(100vh - 70px)',
            width: '100%',
            margin: 0,
            padding: 0,
            display: { xs: 'none', md: 'grid' },
            placeItems: 'end',
            position: 'relative',
          }}
        >
          <Typography
            color='#fff'
            fontSize='32px'
            fontWeight={600}
            height='92px'
            width='324px'
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box
          display='flex'
          height={{ md: '100vh' }}
          alignItems='center'
          flexDirection='column'
        >
          <Box
            width={{ xs: '100%', md: '510px' }}
            p='0px 24px'
            mt={{ md: '70px', xs: '40px' }}
          >
            <Box display='flex' alignItems='center' gap={1} mb='12px'>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={600}>Login</Typography>
            </Box>
            <Typography mb={{ md: '42px', xs: '32px' }}>
              Enter your Email
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Email
                </InputLabel>
                <Controller
                  name='email'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type='text'
                      variant='standard'
                      placeholder='johndoe@gmail.com'
                      InputProps={{ disableUnderline: true }}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ''}
                      sx={{
                        bgcolor: '#F9F9F9',
                        my: 2,
                        borderRadius: '10px',
                        borderStyle: 'none',
                        py: 1,
                        px: 2,
                      }}
                    />
                  )}
                />
              </FormControl>

              <Button
                disabled={mutation.isPending}
                type='submit'
                variant='contained'
                fullWidth
                sx={{
                  fontWeight: 400,
                  borderRadius: '8px',
                  p: '10px 16px',
                }}
              >
                {mutation.isPending ? 'Loading..' : 'Login'}
              </Button>
              {/* <Typography my='10px' textAlign='center'>
                OR
              </Typography>
              <Button
                sx={{ fontWeight: 400, borderRadius: '8px', p: '10px 16px' }}
                fullWidth
                startIcon={<Phone />}
                variant='outlined'
                onClick={() => navigate('/phone-login')}
              >
                Continue with Phone Number
              </Button>
              <Button
                sx={{
                  mt: '14px',
                  fontWeight: 400,
                  p: '10px 16px',
                  borderRadius: '8px',
                  bgcolor: 'black',
                }}
                fullWidth
                startIcon={<Apple />}
                variant='contained'
              >
                Continue with Apple
              </Button> */}
            </form>
            <Typography
              mt={{ md: 18, xs: '35%' }}
              textAlign='center'
              fontSize={14}
            >
              Don't have an account?
              <span
                style={{
                  color: 'blue',
                  fontWeight: 600,
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={() => navigate('/register')}
              >
                Register
              </span>
            </Typography>
            <Box
              mt={{ md: 9, xs: 2 }}
              display='grid'
              sx={{ placeContent: 'center' }}
            >
              <Typography
                fontSize={12}
                textAlign='center'
                mx={{ md: 8, xs: 3 }}
                mb={3}
              >
                Creating an account means you have agree to our{' '}
                <a
                  target='_blank'
                  href='https://move9ja.com/terms-and-conditions/'
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'inherit',
                  }}
                  rel='noreferrer'
                >
                  Terms and Conditions
                </a>
              </Typography>
              {/* <Box display="flex" gap={2}>
                <Button
                  onClick={() => {
                    navigate("/home");
                  }}
                  sx={{
                    height: "48px",
                    width: "160px",
                    borderRadius: "10px",
                    border: "2px solid #F83A59",
                    textTransform: "capitalize",
                    color: "#F83A59",
                  }}
                  startIcon={<Close />}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setSent(!sent);
                  }}
                  sx={{
                    height: "48px",
                    width: "160px",
                    borderRadius: "10px",
                    border: "2px solid #1E4FE0",
                    textTransform: "capitalize",
                    color: "#1E4FE0",
                  }}
                  startIcon={sent ? <Check /> : <AccountCircle />}
                >
                  Sent
                </Button>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
