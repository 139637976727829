import ArrowBack from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { Card, CircularProgress, IconButton, useTheme } from '@mui/material';

import Picture from './../assets/picture.jpg';
import DashboardLayout from './../layouts/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import MessageIcon from './../assets/messageIcon.svg';
import notificationIcon from './../assets/notificationIcon.svg';
import { useEffect, useState } from 'react';

import { useSocketContext } from '../Context/SocketProvider';
import { useQuery } from '@tanstack/react-query';
import { getAllRooms, getNotifications } from '../services/activities';
import { formatDate } from '../utils/formatDate';

const Activities = () => {
  const [value, setValue] = React.useState('mssg');
  const [message, setMessage] = React.useState(false);
  const [notification, setNotification] = React.useState(false);
  const [notificationData, setNotificationData] = useState([]);

  const token = localStorage.getItem('token');
  // console.log('Token', token);

  const BASE = 'wss://www.staging.move9ja.com'; // Use the WebSocket secure (wss) protocol if your server supports it

  const ws = new WebSocket(BASE);

  // When the connection is open
  ws.onopen = () => {
    console.log('WebSocket is connected.');

    // Send a ping message or a test message to the server
    ws.send(JSON.stringify({ type: 'ping' }));
  };

  // Listen for messages from the server
  ws.onmessage = (event) => {
    console.log('Message from server:', event.data);
  };

  // Handle errors
  ws.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  // Handle connection close
  ws.onclose = (event) => {
    console.log('WebSocket is closed.', event.reason);
  };

  const { socket } = useSocketContext();

  console.log('Socket', socket);

  const { data, isLoading } = useQuery({
    queryKey: ['GET_ACTIVITIES'],
    queryFn: getNotifications,
  });

  const { data: rooms, isLoading: isRoomsLoading } = useQuery({
    queryKey: ['GET_ROOMS'],
    queryFn: getAllRooms,
  });

  useEffect(() => {
    console.log('1. Actually enters here');
    if (socket) {
      // Check if the socket is connected
      console.log('Actually enters here');
      socket.on('connect', () => {
        console.log('Socket connected in component');
      });

      // Listening to the 'all notifications' event
      socket.on('all notifications', (data) => {
        console.log('Received notifications:', data);
      });

      // Clean up listener
      return () => {
        socket.off('all notifications');
      };
    }
  }, [socket]);

  const handleChange = (value: string) => {
    setValue(value);
  };

  const navigate = useNavigate();

  const theme = useTheme();

  if (isLoading || isRoomsLoading) {
    return (
      <DashboardLayout>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
          height='100vh'
        >
          <CircularProgress />
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Box p={2} mx={{ md: '14%' }}>
        <Box mb={1}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 2,
            }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Activities
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box
            border='1px solid #E4E7EC'
            bgcolor='#F9FAFB'
            borderRadius='8px'
            display='grid'
            gridTemplateColumns='1fr 1fr'
            sx={{ placeItems: 'center' }}
            mb={2}
            width={{ xs: '100%', md: '342px' }}
          >
            <Typography
              sx={{
                border: value === 'mssg' ? '1px solid #E4E7EC' : '',
                bgcolor: value === 'mssg' ? 'white' : '#F9FAFB',
                borderRadius: '8px',
                p: value === 'mssg' ? '8px 12px' : '',
                width: value === 'mssg' ? '100%' : '',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                color:
                  value === 'mssg' && theme?.palette?.mode === 'dark'
                    ? '#000'
                    : theme?.palette?.mode === 'dark'
                    ? 'grey'
                    : 'inherit',
              }}
              fontWeight={600}
              fontSize={14}
              onClick={() => handleChange('mssg')}
            >
              Messages
            </Typography>
            <Typography
              sx={{
                border: value === 'notify' ? '1px solid #E4E7EC' : '1px',
                bgcolor: value === 'notify' ? 'white' : '#F9FAFB',
                borderRadius: '8px',
                p: value === 'notify' ? '8px 12px' : '',
                width: value === 'notify' ? '100%' : '',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                color:
                  value === 'notify' && theme?.palette?.mode === 'dark'
                    ? '#000'
                    : theme?.palette?.mode === 'dark'
                    ? 'grey'
                    : 'inherit',
              }}
              fontWeight={600}
              fontSize={14}
              onClick={() => handleChange('notify')}
            >
              Notifications*
            </Typography>
          </Box>
          <Box>
            {value === 'mssg' && (
              <Box>
                {!message ? (
                  <Box>
                    {[1, 2, 3, 4, 5, 6].map((_, i) => (
                      <MessageCard key={i} />
                    ))}
                  </Box>
                ) : (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    height={{ xs: '60vh', md: '50vh' }}
                    flexDirection='column'
                    gap='12px'
                  >
                    <img
                      src={MessageIcon}
                      alt='message-icon'
                      height='48px'
                      width='48px'
                    />
                    <Typography fontSize='12px' fontWeight={600}>
                      No active message as the moment
                    </Typography>
                    <Typography fontSize='12px' fontWeight={400}>
                      Messages appear usually during a live trip
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {value === 'notify' && (
              <Box>
                {data?.data?.notification?.length && !isLoading ? (
                  <Box>
                    {data?.data?.notification.map(
                      (data: any, index: number) => (
                        <NotificationCard key={index} data={data} />
                      )
                    )}
                  </Box>
                ) : (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    height={{ xs: '60vh', md: '50vh' }}
                    flexDirection='column'
                    gap='12px'
                  >
                    <img
                      src={notificationIcon}
                      alt='notif-icon'
                      height='48px'
                      width='48px'
                    />
                    <Typography fontSize='12px' fontWeight={600}>
                      No new notification at the moment
                    </Typography>
                    <Typography fontSize='12px' fontWeight={400}>
                      New notifications will appear here
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Activities;

const MessageCard = () => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        p: 1,
        border: 1,
        borderColor: 'divider',
        width: '100%',
        borderRadius: 2,
        display: 'flex',
        justifyContent: { xs: 'space-between', md: 'normal' },
        alignItems: 'start',
        gap: 1,
        mb: 1,
        cursor: 'pointer',
      }}
      elevation={0}
      onClick={() => {
        navigate('/messages');
      }}
    >
      <img
        src={Picture}
        alt=''
        style={{ borderRadius: '100%', height: 59, width: 59 }}
      />
      <Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          mb={1}
        >
          <Typography fontSize={12} fontWeight={600}>
            Joe Doe sent a new message
          </Typography>
          <Typography fontSize={12} color='#98A2B3'>
            18h
          </Typography>
        </Box>
        <Typography fontSize={12} borderLeft={1} pl={0.5} color='#98A2B3'>
          Hello, I noticed you haven’t updated your travel status, kindly do so
        </Typography>
      </Box>
    </Card>
  );
};

const NotificationCard = ({ data }: { data: any }) => {
  return (
    <Card
      sx={{
        p: 1,
        border: 1,
        borderColor: 'divider',
        width: '100%',
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        gap: 1,
        mb: 1,
      }}
      elevation={0}
    >
      <Box width='100%'>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
          mb={1}
        >
          <Typography fontSize={12} fontWeight={600}>
            {data.title}
          </Typography>
          <Typography fontSize={12} color='#98A2B3'>
            {formatDate(data.timestamp)}
          </Typography>
        </Box>
        <Typography fontSize={12} pl={0.5} color='#98A2B3'>
          {data.message}
        </Typography>
      </Box>
    </Card>
  );
};
